<template>
  <div>
    <PersonnelAward/>
  </div>
</template>

<script>
import PersonnelAward from '../../components/personnelAward.vue';

export default {
    name: 'HRvecPersonnelAward',
    data() {
        return {};
    },
    mounted() {
    },
    methods: {},
    components: { PersonnelAward }
};
</script>

<style lang="scss" scoped>

</style>